<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      ML - Versionen
    </div>


    <div class="scrollbar-container">
      <table class="table table-bordered" style="width:80%; margin:0 auto;">
        <thead>
          <tr>
            <th scope="col">Veröffentlichung</th>
            <th scope="col">Versionsnummer</th>
            <th scope="col">Externer Link</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="version in versions" :key="'version_tr_'+version.id">
            <th scope="row">{{ version.releaseDate }}</th>
            <td>{{ version.versionNumber }}</td>
            <td>{{ version.externalLink }}</td>
          </tr>
        </tbody>
      </table>
    </div>



  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
 components: { 

  },
  name: 'superadminVersions',
  data () {
    return {
      versions: [],
    }
  },
  created: function () {
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser']),
    headerButtons() {
      let view = this;
      let state= { buttons: [] };
      state.buttons.push({ type: "block", icon: "far fa-layer-plus", click: () => { view.openNewVersion(); }, tooltip: 'Neue Release-Version anlegen' }); 
      return state;
    },
  },
  watch: {
  },
  // methods that implement data logic.
  methods: {
    refresh: function(){
        this.loading = true;
        this.loadVersions();
    },

    openNewVersion(){
        let view = this;
        let dataEntries = [
            {label: 'Veröffentlichungs Datum: ', key: 'releasedate', hidden: false, type: 'text', value: '', length: 128,styles: {'width': '350px'}},
            {label: 'Versionsnummer: ', key: 'versionnumber', hidden: false, type: 'text', value: '', length: 128, styles: {'width': '350px'}},
            {label: 'Externer Link', key: 'externallink', hidden: false, type: 'text', value: '', length: 128, styles: {'width': '350px'}},
            {flexDivider: true, styles: {'margin-bottom': '23px'}},
        ];
        this.$helpers.OpenPopup({
            elRelative: null,
            type: 'datafill',
            pointerDirection: 0,
            componentState: {
                entries: dataEntries,
                selectCallback: function (newVersion) {
                    view.createVersion(newVersion);
                },
                errorCheck: {
                }
            },
            header: 'Neue Software-Version anlegen',
            remainAfterSelect: false,
            centered: true,
            expanded: true,
        });
    },
    createVersion(newVersion){
        let view = this;
        let url = '/api/superuser/version';
        let payload = {
          releaseDate: newVersion.releasedate,
          versionNumber: newVersion.versionnumber,
          externalLink: newVersion.externallink
        }
        this.axios({
            method: 'post',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
        }).then(response => {
            view.$helpers.success('Erstellt', 'Eine neue Version wurde erstellt');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        })
    },

    
    loadVersions: function(force = false){
      let view = this;
      let url = '/api/superuser/version';
      
      this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
      }).then(response => {
        view.versions = response.data.data;
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3{
  width: 400px; 
  text-align:left; 
  float:left; 
  margin-left:25px; 
  margin-right:25px;
  overflow-y: auto;
  max-height: 800px;
}

</style>
